.#{$prefix}-form{
  &-line{
    margin-bottom: 15px;
    &:last-child{
      margin-bottom: 0;
    }

    & > * { 
      width: 100%;
    }
  }
}
