.secondary-filter-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .main-currency-btn {
    margin-right: 10px;

    &__select {
      >.MuiInputBase-root {
        width: 93px;
        
        @media (min-width: $breakpoint-xs) {
          width: 110px;
        }
        @media (min-width: $breakpoint-s) {
          width: 145px;
        }
      }
    }

    .title-main {
      display: none;

      @media (min-width: $breakpoint-s) {
        display: inline;
      }
    }
  }

  .main-currency-btn,
  .sort-by-btn {
    position: relative;
    display: block;
    top: 10px;

    @media (min-width: $breakpoint-s) {
      margin-right: 25px;
    }

    .title {
      position: absolute;
      top: -5px;
      left: 0;
      font-family: $font-primary;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.2;
      letter-spacing: 0;
      pointer-events: none;
      user-select: none;

      @media (min-width: $breakpoint-xs) {
        font-size: 14px;
      }
      @media (min-width: $breakpoint-s) {
        display: block;
      }
    }

    &__select {
      position: relative;

      &:hover {

        .main-currency-btn__select__icon,
        .sort-by-btn__select__icon {
          border-color: $color-font-primary;
        }
      }

      .MuiInputBase-root {
        height: 43px;

        @media (min-width: $breakpoint-s) {
          height: 50px;
        }

        .MuiSelect-select {
          &.MuiSelect-select {
            font-size: 13px;
            padding-right: 45px;
            background-color: transparent;
            @media (min-width: $breakpoint-xs) {
              font-size: 15px;
            }
          }
        }

        .MuiSvgIcon-root {
          display: none;
        }

        &:before,
        &:after {
          display: none;
        }
      }

      &__icon {
        position: absolute;
        top: -2px;
        pointer-events: none;
        user-select: none;
        background-color: $white;
        z-index: 1;
        right: 0;
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        border-radius: 35px;
        border: 1px solid $color-border;

        .MuiSvgIcon-root {
          display: block;
          font-size: 20px;
        }
      }
    }
  }

  .btns-right {
    display: none;

    @media (min-width: $breakpoint-s) {
      display: flex;
    }
  }
}