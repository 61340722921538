@import "../../../theme/scss/variables/variables";
@import '../../../theme/scss/components/_typography.scss';

$spacing-divider-search-panel: 20px;

.search-panel {
  position: relative;
  height: 100%;

  @media (min-width: $breakpoint-xs) {
    overflow: hidden;
  }

  &>form {
    height: 100%;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $spacing-divider-search-panel;
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    font-size: 20px;

    &:hover {
      text-decoration: underline;

      .close-icon {
        border-color: $color-font-primary;
      }
    }

    .close-icon {
      //Change for .circle icon
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      border-radius: 35px;
      border: 1px solid $color-border;

      .MuiSvgIcon-root {
        display: block;
        font-size: 20px;
      }
    }
  }

  &__wrap {
    &.search-form-wrap {
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 20px;
      padding-right: 20px;

      @media (min-width: $breakpoint-xs) {
        padding-bottom: 90px;
        height: 100%;
        overflow: auto;

      }
    }

    &.search-button-container {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      bottom: 0;
      left: 0;
      height: 90px;
      background-color: $white;
      border-top: 1px solid $color-border;

      @media (min-width: $breakpoint-xs) {
        position: absolute;
      }

      .search-button {
        width: 100%;

        .#{$prefix}-btn.MuiButton-root {
          width: 100%;
        }
      }
    }
  }

  &__item {
    margin-bottom: $spacing-divider-search-panel;

    &.has-border-bottom {
      padding-bottom: $spacing-divider-search-panel;
      border-bottom: 1px solid $color-border;
    }
    &.has-sub-filter{

      .search-panel__item{
        margin-bottom: 15px;
        &.has-border-bottom{
          padding-bottom: 15px;
        }
      }
      > .MuiAccordion-root {
        .MuiAutocomplete-endAdornment{
          bottom: 6px;
        }
        .MuiAccordion-root{
          .accordion-title {
            font-size: 14px;
          }
        }
        > .MuiCollapse-root {
          padding-left: 15px;
          border-left: 1px solid $color-border;
          .MuiAccordionDetails-root {
            .MuiFormControlLabel-label {
              font-size: 14px;
            }
            .MuiFormControl-root{
              padding-top: 0;
              > .MuiFormControl-root{
                padding-top: 5px;
                padding-bottom: 5px;
              }
            }
          }
        }
      }
    }

    &.has-border-top {
      padding-top: $spacing-divider-search-panel;
      border-top: 1px solid $color-border;
    }

    .search-bar {
      display: flex;
      align-items: center;

      &>.MuiFormControl-root {
        flex-grow: 1;
      }

      input[type="search"] {
        font-size: 14px;
      }

      .tooltip {
        margin-left: 10px;
        flex-shrink: 0;
        font-weight: 500;
        font-size: 16px;
        cursor: pointer;
        position: relative;

        &:hover {
          .tooltip__hovered {
            display: block;
          }
        }

        &__hovered {
          display: none;
          z-index: 2;
          width: 250px;
          position: absolute;
          top: 40px;
          right: 0px;
          background-color: $white;
          border: 1px solid $color-border;
          padding: 10px;
          font-family: $font-primary;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 1.2;
          letter-spacing: 0;
        }
      }
    }

    .strict-keyword {}

    .keyword-explain{
      margin-top: 10px;
      padding-bottom: 5px;
      border-radius: 8px;
      transition: .3s;
      &.is-highlighted {
        padding: 10px;
        box-shadow: 0px 4px 8px rgb(0, 0, 0, .3);
      }
      p{
        font-size: 13px;
        color: $color-font-light;
        &:first-child{
          padding-bottom: 5px;
        }
      }
    }

    > .MuiFormControl-root {
      width: 100%;
    }

  }

  &__divider {
    height: 1px;
    background-color: $color-border;
  }

  &__row {
    .MuiFormControl-root {
      display: flex;

      .MuiFormControlLabel-root {
        margin-left: 0;
        width: 100%;

        .MuiButtonBase-root {}

        .MuiFormControlLabel-label {
          flex-grow: 1;
        }
      }
    }
  }

  &__double-row {
    display: flex;
    margin-left: -6px;
    margin-right: -6px;

    &>.MuiFormControl-root,
    &>* {
      margin: 0;
      width: 50%;
      flex-basis: 50%;
      padding-left: 6px;
      padding-right: 6px;
    }

    .is-datepicker-component {
      .MuiInputBase-root input {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }

  &__stone-row {
    display: flex;
    margin-left: -6px;
    margin-right: -6px;

    .MuiFormControl-root {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    &>.MuiFormControl-root,
    &>* {
      margin: 0;
      width: 25%;
      flex-basis: 25%;
      padding-left: 6px;
      padding-right: 6px;

      .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
        padding-left: 3px;
        padding-right: 3px;

        p {
          position: relative;
          top: 1px;
          font-size: 11px;
        }
      }
    }

    &>*:nth-child(n+2) {
      input {
        padding-left: 10px;
        padding-right: 0px;

        &::placeholder {
          font-size: 12px;
        }
      }
    }

    &>*:first-child {
      width: 50%;
      flex-basis: 50%;

      .MuiFormHelperText-root {
        width: 200%;
      }
    }

    .is-datepicker-component {
      .MuiInputBase-root input {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }

  &__treated {
    padding-top: 10px;
    padding-bottom: 15px;

    >.MuiFormControl-root {
      padding-top: 0 !important;
      padding-bottom: 0 !important;

      .MuiFormControlLabel-label {
        padding-left: 9px;
      }
    }
  }

  &__sub-item-checkbox {
    width: 100%;
  }

  &__sub-item {
    width: 100%;

    .MuiFormControl-root {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
  .MuiAccordion-root {
    &.sub-accordion {
      margin-top: 15px!important;
      .accordion-title {
        font-size: 16px;
      }
      .MuiAccordionDetails-root{
        padding: 0 0 0 15px!important;
        .MuiFormControlLabel-label {
          font-size: 14px;
        }
      }
    }
    border-radius: 0 !important;
    box-shadow: none;
    &::before{
      background-color: transparent;
    }

    .MuiAutocomplete-endAdornment{
      bottom: 13px;
      top: auto;
    }

    .Mui-expanded{
      margin-top: 0;
    }

    .accordion-title {
      @include heading-3();
      padding-right: 10px;
      width: 100%;
      margin-bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .accordion-title-amount{
        flex-shrink: 0;
        float: right;
        color: $color-font-light;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .MuiAccordionSummary-root {
      min-height: 0;
      padding-left: 0;
      padding-right: 0;
      border-radius: 0 !important;

      .MuiAccordionSummary-content {
        margin: 0;
      }

      .MuiIconButton-root {
        padding: 0;
        margin: 0;
      }
    }

    .MuiCollapse-root {
      .MuiAccordionDetails-root {
        padding: 10px 0 0 0;
        flex-wrap: wrap;

        .MuiFormControl-root {
          display: flex;
          flex-shrink: 0;
          width: 100%;
          padding-top: 10px;
          padding-bottom: 10px;

          .MuiFormControlLabel-root {
            width: 100%;
            margin-left: 0;

            .MuiIconButton-root {
              padding: 0;
            }

            .MuiFormControlLabel-label {
              flex-grow: 1;
            }
          }
        }
      }
    }
  }
}

.accordion-subtitle {
  font-family: $font-primary;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0;
  color: $color-font-light;
}