@import "../../theme/scss/variables/variables";

.page-category {
	display: flex;
	&__filters-overlay {
		user-select: none;
		pointer-events: none;
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.9);
		z-index: -1;
		opacity: 0;
		transition: opacity 0.3s;
		.page-category.is-filter-open & {
			z-index: 7;
			opacity: 1;
			user-select: auto;
			pointer-events: auto;
		}
	}
	&__filters {
		flex-shrink: 0;
		border-right: 1px solid $color-border;
		position: fixed;
		width: 100%;
		max-width: 400px;
		height: 100%;
		background-color: $white;
		z-index: 8;
		top: 0;
		transform: translate3d(-100%, 0, 0);
		transition: 0.3s;
		overflow: auto;
		.page-category.is-filter-open & {
			transform: translate3d(0, 0, 0);
		}
    @media (min-width: $breakpoint-xs) {
      height: 100vh;
    }

		/* @media (min-width: $breakpoint-s) {
      height: 100%;
      overflow: auto;
      transform: translate3d(0, 0, 0);
			top: 0;
			position: relative;
			width: 350px;
			flex-basis: 350px;
			display: block;
		}
		
    @media (min-width: $breakpoint-m) {
			width: 400px;
			flex-basis: 400px;
		} */
	}
	&__results {
		padding-bottom: 40px;
		padding-top: 0;
		flex-grow: 1;
		&__buttons {
			position: sticky;
			top: 0;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 10px;
			z-index: 5;
			background-color: $white;
			padding-top: 20px;
			padding-bottom: 20px;
			border-bottom: 1px solid $color-border;
      @media (max-width: $breakpoint-xs--max) {
        padding-right: 10px;
        padding-left: 10px;
      }
			@media print {
				position: relative;
			}
			.#{$prefix}-btn.MuiButton-root {
				@media (max-width: $breakpoint-s--max) {
					font-size: 12px;
					padding-left: 10px;
					padding-right: 10px;
					margin-left: 5px;
					margin-right: 5px;
					&:first-child {
						margin-left: 0px;
					}
					&:last-child {
						margin-right: 0px;
					}
				}
				@media (min-width: $breakpoint-s) {
					min-width: 135px;
				}
			}
			&__open-filter {
				cursor: pointer;
				font-size: 15px;
				display: inline-flex;
				align-items: center;
				padding-right: 15px;
        @media (min-width: $breakpoint-xs) {
          font-size: 18px;
          padding-right: 20px;
        }
				&:hover {
					text-decoration: underline;
					.filter-icon {
						border-color: $color-font-primary;
					}
				}
				.filter-icon {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 35px;
					height: 35px;
					border-radius: 35px;
					border: 1px solid $color-border;
					@media (min-width: $breakpoint-xs) {
						margin-left: 10px;
					}
					.MuiSvgIcon-root {
						display: block;
						font-size: 20px;
					}
				}
				.filter-text {
					display: none;
					@media (min-width: $breakpoint-xs) {
						display: block;
					}
				}
			}
			&__right {
				padding-left: 15px;
				flex-grow: 1;
				border-left: 1px solid $color-border;
				@media (min-width: $breakpoint-xs) {
          padding-left: 20px;
				}
				@media (min-width: $breakpoint-s) {
					justify-content: space-between;
				}
				&__info {
          flex-grow: 1;
          display: none;
          @media (min-width: $breakpoint-m) {
            display: block;
					}
					.title {
						font-family: $font-primary;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 1.2;
						letter-spacing: 0;
					}
					.chip-container {
						margin-top: 5px;
						display: flex;
						column-gap: 6px;
						align-items: center;
						.title {
							display: none;
							@media (min-width: $breakpoint-m) {
								display: block;
							}
						}
						&__chips {
							display: flex;
							column-gap: 10px;
							align-items: center;
						}
            .tooltip{
              font-style: normal;
              margin-left: 7px;
              .tooltip__hovered{
                width: 300px;
                font-size: 12px;
                font-style: italic;
              }
              .circle-icon{
                text-transform: lowercase;
              }
            }
						.MuiChip-root {
							.MuiChip-label {
								font-size: 12px;
							}
						}
					}
				}
			}
		}
		&__result {
			display: grid;
			grid-template-columns: repeat(2, minmax(0, 1fr));
			column-gap: 15px;
			row-gap: 40px;

			@media (min-width: $breakpoint-s) {
				grid-template-columns: repeat(4, minmax(0, 1fr));
			}
			@media (min-width: $breakpoint-l) {
				grid-template-columns: repeat(5, minmax(0, 1fr));
			}
			@media (min-width: $breakpoint-xl) {
				grid-template-columns: repeat(6, minmax(0, 1fr));
			}

			@media print {
				display: block !important;
			}
		}
		&__noresult {
      padding-top: 50px;
			font-family: $font-primary;
			font-size: 20px;
			font-style: italic;
			font-weight: 400;
			line-height: 1.2;
			letter-spacing: 0;
      text-align: center;
		}
		&__pagination {
			margin-top: 40px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			@media (min-width: $breakpoint-s) {
				flex-direction: row;
				grid-template-columns: repeat(4, minmax(0, 1fr));
			}
			.text {
				margin-left: 10px;
				margin-right: 10px;
			}

      .pagination-spe{
        display: flex;
        column-gap: 10px;
				margin-top: 20px;
				@media (min-width: $breakpoint-s) {
					margin-top: 0px;
					border-left: 1px solid $color-border;
					padding-left: 25px;
					margin-left: 10px;

				}
        input{
          width: 40px;
          padding: 6px 8px;
          text-align: center;
        }
        button{
          height: 32px;
          width: 32px;
          border-radius: 20px;
          border: 0;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
		}
	}

	&__item {
		@media print {
			float: left;
			width: 33.33% !important;
			position: relative !important;
			page-break-inside: avoid !important;
		}
	}
}

.circle-icon {
	.MuiSvgIcon-root {
		display: block;
	}
	.MuiIconButton-root:hover {
		background-color: transparent;
	}
}
