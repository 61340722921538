@import "../../theme/scss/variables/variables";

.page-view-category {
  padding-top: 0px;
  padding-bottom: 40px;

  &__buttons {
    position: sticky;
    padding: 20px 0;
    top: 0;
    z-index: 5;
    background-color: $white;
    margin-bottom: 40px;

    @media print {
      display: none;
    }
  }

  &__no-product {
    display: flex;
    justify-content: center;
    padding: 50px 30px 0 30px;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;

    @media (min-width: $breakpoint-s) {
      flex-wrap: nowrap;
    }

    @media print {
      padding-top: 30px;
      flex-wrap: nowrap;
      margin-bottom: 0;
    }

    &__carousel {
      position: relative;
      width: 100%;
      flex-basis: 100%;
      flex-shrink: 0;
      margin-bottom: 40px;

      @media (min-width: $breakpoint-s) {
        margin-bottom: 0px;
        width: 300px;
        flex-basis: 300px;
      }

      @media (min-width: $breakpoint-m) {
        width: 500px;
        flex-basis: 500px;
      }

      @media print {
        margin-bottom: 0px;
        width: 300px;
        flex-basis: 300px;
      }

      &.one-image {

        .prev,
        .next {
          display: none;
        }
      }

      &__no-image {
        height: 350px;
        overflow: hidden;
        border: 1px solid $color-border;

        .no-image {
          width: 100%;
          height: 100%;
          object-position: center;
          object-fit: contain;
          display: block;
        }
      }

      .box {
        $thumbnails-width: 60px;
        position: relative;

        &>div:first-child {
          width: calc(100% - #{$thumbnails-width});
          position: relative;
          left: $thumbnails-width;
        }

        .carousel-container {
          overflow: hidden;
          height: 350px;

          @media print {
            height: 300px !important;
          }

          .prev,
          .next {
            color: $color-border;

            &:hover {
              color: white;
            }

            @media print {
              display: none;
            }
          }

          .dots {
            display: none;
          }

          .carousel-item {
            .pause-icon {
              opacity: 0;
            }
          }
        }

        .thumbnails {
          height: 100%;
          width: $thumbnails-width;
          position: absolute;
          top: 0;
          left: 0;
          margin: 0;
          display: block;
          cursor: pointer;
          overflow: auto;

          @media (min-width: $breakpoint-s) {
            height: 350px;
          }

          &::-webkit-scrollbar {
            all: unset;
            width: 8px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: ButtonFace;
            border-radius: 10px;
            box-shadow: inset 0px 0px 0px 20px rgba(128, 128, 128, 0.3);
            border-left: 2px solid rgba(255, 255, 255, 0.3);
            border-right: 2px solid rgba(255, 255, 255, 0.3);
          }

          &::-webkit-scrollbar-thumb:hover {
            box-shadow: inset 0px 0px 0px 20px rgba(128, 128, 128, 0.5);
          }

          &::-webkit-scrollbar-thumb:active {
            box-shadow: inset 0px 0px 0px 20px rgba(128, 128, 128, 0.7);
          }

          &::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: ButtonFace;
            box-shadow: inset 0px 0px 0px 20px rgba(255, 255, 255, 0.3);
          }

          @media print {
            width: 40px;
            height: 300px !important;
          }

          .thumbnail {
            width: $thumbnails-width - 10px;
            height: $thumbnails-width - 10px;
            object-fit: cover;
            margin: 2px 0;
            border: 1px solid transparent;

            @media print {
              width: 40px;
              height: 40px;
            }

            &.active-thumbnail {
              border: 1px solid $color-border;
            }
          }
        }
      }

      .btn-view-image-fullscreen {
        z-index: 1;
        position: absolute;
        bottom: 0px;
        right: 10px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        cursor: pointer;

        &:hover {
          background-color: $grey-2;
        }

        @media print {
          display: none;
        }

        .MuiSvgIcon-root {
          font-size: 20px;
        }
      }
    }

    &__info {
      flex-grow: 1;
      padding-left: 20px;

      @media (min-width: $breakpoint-s) {}

      @media print {
        padding-left: 20px;
      }

      &__block {
        margin-bottom: 30px;
        text-transform: capitalize;

        @media print {
          margin-bottom: 20px;
        }

        .title{
          .tooltip{
            padding-left: 10px;
            display: inline-block;
            &__hovered{
              top: 30px;
              width: 240px;
              text-align: center;
            }
          }
        }

        &>* {
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .tag {
        color: $color-font-light;
        border: 1px solid $color-border;
        padding: 5px;
        font-size: 16px;
        display: inline-flex;
      }
    }
  }

  &__info {
    margin: 0 auto;
    max-width: 800px;

    &__line {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      @media print {
        margin-bottom: 13px;
      }

      &.long-content {
        align-items: flex-start;

        @media (max-width: $breakpoint-s--max) {
          flex-wrap: wrap;

          .page-view-category__info__line__label {
            width: 100%;
            flex-basis: 100%;
          }
        }
      }

      &.is-url {
        @media print {
          display: none;
        }
      }

      &__label {
        flex-shrink: 0;
        width: 120px;
        flex-basis: 120px;
        margin-bottom: 10px;
        font-weight: 600;

        @media (min-width: $breakpoint-s) {
          width: 200px;
          flex-basis: 200px;
        }

        @media print {
          margin-bottom: 3px;
        }

        a {
          color: #2676ae;
        }
      }

      &__content {
        flex-grow: 1;
        margin-bottom: 10px;

        a {
          /* These are technically the same, but use both */
          overflow-wrap: break-word;
          word-wrap: break-word;

          -ms-word-break: break-all;
          /* This is the dangerous one in WebKit, as it breaks things wherever */
          word-break: break-all;
          /* Instead use this non-standard one: */
          word-break: break-word;

          /* Adds a hyphen where the word breaks, if supported (No Blink) */
          -ms-hyphens: auto;
          -moz-hyphens: auto;
          -webkit-hyphens: auto;
          hyphens: auto;
        }

        &.content-product-notes {
          &.has-notes {
            border-bottom: 1px solid $color-border;
          }
        }

        .view-product-row-textarea {
          padding-bottom: 15px;
        }

        .edit-button {
          display: flex;
          align-items: center;
          column-gap: 10px;
          padding: 10px 0;

          &:first-child {
            padding-top: 0;
          }

          &:hover {
            .circle-icon {
              border-color: $color-font-primary;
            }
          }

          &__text {
            font-size: 16px;
          }
        }

        .edit-note-form {
          display: block;

          form {
            .edit-note-form-save {
              display: flex;
              align-items: center;
              padding: 10px 0;
            }

            .loader-icon {
              color: $white;
            }
          }
        }

        .individual-history-info {
          padding: 10px 0;

          >div {
            margin-bottom: 5px;
          }
        }

        .other-sales {
          margin-bottom: 20px;
          padding-bottom: 20px;
          border-bottom: 1px solid $color-border;

          &:last-child {
            margin-bottom: 0;
            //padding-bottom: 0;
            border: 0;
          }
        }
      }
    }
  }

  .loader-icon {
    color: $color-font-primary;
  }
}

.view-product-row-edit {
  display: flex;
  align-items: center;

  .circle-icon {
    margin-left: 10px;
  }

  &__info {
    display: flex;
    align-items: center;

    &__edited {
      color: $color-gold;
      font-size: 15px !important;
      margin-right: 5px;
    }

    .tooltip__hovered {
      width: 300px;
    }
  }

  &__form {
    display: flex;
    align-items: center;

    form {
      display: flex;
      align-items: center;

      p.Mui-error {
        display: none;
      }
    }

    &.form-year {
      align-items: flex-end;

      form {
        align-items: flex-end;

        .circle-icon {
          position: relative;
          top: -3px;
        }
      }

    }
  }
}

.carousel-image-full-screen {
  width: 100vw;
  height: 100vh;
  background-color: $white;
  position: fixed;
  top: 0;
  left: 0;
  //z-index: 999;
  //display: none;
  transform: scale(0.8);
  opacity: 0;
  user-select: none;
  pointer-events: none;
  z-index: -1;

  &.is-full-screen {
    transition: 0.3s;
    z-index: 999;
    opacity: 1;
    user-select: auto;
    pointer-events: auto;
    transform: scale(1);
    display: flex;
    flex-direction: column;
  }

  &__header {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: $black;
    height: $header-height;
    padding: 0 $container-padding-lr;
  }

  &__image {
    flex-grow: 1;
    padding: 0;
    overflow: hidden;    

    .fullImageZoom{
      width: 100%;
      height: 100%;
      background-color: $white;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.individual-history {
  &-title {
    margin-bottom: 10px;
  }

  &-block {
    margin-bottom: 10px;
    border: 1px solid $color-border;
    border-radius: 4px;
    padding: 7px;
  }

  &-info {
    font-family: $font-primary;
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.2;
    letter-spacing: 0;
    color: $color-font-light;

    .name {
      color: $color-font-primary;
      font-weight: 400;
    }

    .date {
      color: $color-font-primary;
      font-weight: 400;
    }
  }

  &-number {
    font-family: $font-primary;
    color: $color-font-primary;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0;
    margin-bottom: 5px;

    @include srss-themify($themes) {
      background-color: getThemeColor("main-header");
    }
  }
}