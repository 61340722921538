.cursor-pointer{
  cursor: pointer;
}

.d-block {
  display: block;
}

.w-100pct{
  width: 100%;
}
.w-130px{
  width: 130px;
}


.fc-light{
  color: $color-font-light;
}


.hide-xs-max{
  @media (max-width: $breakpoint-xs--max) {
    display: none!important;
  }
}
.hide-s-max{
  @media (max-width: $breakpoint-s--max) {
    display: none!important;
  }
}