@import "../../theme/scss/variables/variables";

.login-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  form {
    width: 300px;
    max-width: 100%;
  }
  .terms{
    margin-top: 30px;
    text-align: center;
    font-size: 13px;
  }
}
.login-logo {
  margin-bottom: 50px;
  img {
    display: block;
    width: 350px;
    max-width: 100%;
    margin: 0 auto;
  }
}

.forgot-password {
  color: $color-font-primary;
  text-decoration: underline;
}
.wrong-email,
.change-email{
  cursor: pointer;
  color: $color-font-primary;
  text-decoration: underline;
  margin-bottom: 14px;
}
.change-email{
  font-size: 12px;
}
