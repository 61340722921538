@import "../../theme/scss/variables/variables";

.page-product-form{
  margin-top: 50px;
  padding-bottom: 30px;
  & > .MuiGrid-root{
    position: relative!important;
    margin: 0 auto!important;
    
  }
}

.product-form{
  margin: 0 auto;
  max-width: 800px;
  &__row{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    @media (min-width: $breakpoint-s) {
      flex-direction: row;
		}
  }
  &__buttons{
    display: block;
  }
  &__label{
    width: 100%;
    padding-right: 20px;
    @media (min-width: $breakpoint-s) {
      padding-top: 18px;
      padding-right: 0;
      width: 200px;
      flex-shrink: 0;
    }
  }  
  &__field{
    flex-grow: 1;
    & > .MuiFormControl-root{
      display: flex;
      .price-field{
        max-width: 220px;
      }
    }

    &.multiple-rows{
      display: flex;
      align-items: center;
      column-gap: 20px;
    }
    
    .field-textarea{
      width: 100%;
      @media (min-width: $breakpoint-s) {
        min-width: 500px;
      }

    }
  }  
}