.#{$prefix}-container-page,
.#{$prefix}-container-page-mw{
  padding-left: $container-padding-lr--s;
  padding-right: $container-padding-lr--s;
  @media (min-width: $breakpoint-xs){
    padding-left: $container-padding-lr;
    padding-right: $container-padding-lr;
  }
}

.#{$prefix}-container-page-mw{
  max-width: 1200px;
	margin: 0 auto;
}

.#{$prefix}-inner-page{
  padding-top: 40px;
  padding-bottom: 40px;
}
.#{$prefix}-inner-t{
  padding-top: 40px;
}