.wrap-btn{
  display: flex;
  align-items: center;
  &.center{
    justify-content: center;
  }
  &.right{
    justify-content: flex-end;
  }
  &.space-around{
    justify-content: space-around;
  }
  .#{$prefix}-btn{
    margin-left: 10px;
    margin-right: 10px;
    &:first-child{
      margin-left: 0px;
    }
    &:last-child{
      margin-right: 0px;
    }
  }  
}

.#{$prefix}-btn,
.#{$prefix}-btn.MuiButton-root{
  padding: 10px 20px;
  background-color: $color-primary;
  color: $black;
  border-radius: 0;
  text-transform: none;
  font-size: 16px;
  box-shadow: none;
  text-decoration: none;
  &:disabled{
    opacity: .5;
  }
  @include srss-themify($themes) {
    background-color: getThemeColor("button-bg");
    color: getThemeColor("button-font");
  }
  &:hover{
    background-color: darken($color-primary, 10%);
    box-shadow: none;
    @include srss-themify($themes) {
      background-color: getThemeColor("button-bg-hover");
    }
  }
  &.#{$prefix}-btn--large{
    @media (min-width: $breakpoint-xs) {
      min-width: 135px;
    }
  }
  &.#{$prefix}-btn--small{
    line-height: 1.2;
    padding: 10px 15px;
  }
  &.#{$prefix}-btn--xsmall{
    line-height: 1;
    padding: 7px 10px;
  }
  &.#{$prefix}-btn--secondary{
    background-color: $white;
    border: 1px solid $black;
    color: $black;
    &:hover{
      background-color: darken($white, 10%);
      box-shadow: none;
    }
  }
  &.#{$prefix}-btn--third{
    @include srss-themify($themes) {
      background-color: getThemeColor("secondary");
      border: 1px solid getThemeColor("secondary");
    }
    color: $color-font-primary;
    &:hover{
      @include srss-themify($themes) {
        background-color: getThemeColor("secondary");
        background-color: darken(getThemeColor("secondary"), 10%);
      }
      box-shadow: none;
    }
  }
  &.#{$prefix}-btn--secondary-reverse{
    background-color: $black;
    border: 1px solid $white;
    color: $white;
    &:hover{
      background-color: darken($black, 10%);
      box-shadow: none;
    }
  }
  &.#{$prefix}-btn--rounded{
    border-radius: 50px;
  }
  &.#{$prefix}-btn--link{
    background-color: transparent;
    border: 0;
    color: $black;
    text-decoration: underline;
    &:hover{
      background-color: transparent;
      box-shadow: none;
    }
  }
  &.no-border{
    border: 0;
  }
}

