* {
  //init box sizing for each elements
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased !important;
  text-rendering: optimizeSpeed !important;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

html {
  font-size: 1rem;
}
html,
body {
  background-color: $white;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility !important;
}

body {
  color: $black;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  width: 100%;
  background-color: $white;
  font-size: 16px;
  font-family: $font-primary;
  &.stop-scroll{
    overflow: hidden;
  }
}