@import './theme-van-cleef';
@import './theme-jewels-for-eternity';

$themes: (
  van-cleef: $theme-van-cleef,
  jewelsforeternity: $theme-jewels-for-eternity
);

/*
 * Implementation of themes
 */
 @mixin srss-themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function getThemeColor($key) {
  @return map-get(map-get($theme-map, colors), '#{$key}');
}
