.circle-icon {
	display: flex;
	align-items: center;
	justify-content: center;
  width: 35px;
	height: 35px;
	border-radius: 35px;
	border: 1px solid $color-border;
  &--small{
    width: 22px;
	  height: 22px;
    font-size: 14px;
    .MuiSvgIcon-root{
      font-size: 14px;
    }
  }
  &.is-disabled {
		cursor: default;
		pointer-events: none;
		opacity: 0.5;
	}
  &:hover{
    border-color: $color-font-primary;
  }
  .MuiSvgIcon-root{
    font-size: 15px;
  }
}
