@import "../../../theme/scss/variables/variables";

$color-sold: #0dac3a;
$color-notsold: #ac280d;
$color-forsale: #ddb812;

.#{$prefix}-product-item{
  display: block;
  cursor: pointer;
  text-decoration: none;
  color: $color-font-primary;
  border: 1px solid transparent;
  height: 100%;
  padding: 15px 5px;
  @media (min-width: $breakpoint-s){
    padding: 15px;
  }
  &:hover{
    border: 1px solid $color-border;
  }
  
  &__image{
    position: relative;
    width: 100%;
    height: 200px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    padding: 10px;
    @media (min-width: $breakpoint-m){
      height: 300px;
    }
    @media print{
      height: 150px;
    }
    .sold-icon{
      z-index: 1;
      display: block;
      position: absolute;
      bottom: 10px;
      right: 10px;
      width: 10px;
      height: 10px;
      cursor: pointer;
      &.is-visible{
        .sold-icon__tooltip{
          opacity: 1;
        }
      }
      &:hover{
        .sold-icon__tooltip{
          opacity: 1;
        }
      }
      &.not-sold{
        .sold-icon__circle{
          background-color: $color-notsold;
        }
        .sold-icon__tooltip{
          border-color: $color-notsold;
        }
      }
      &.for-sale{
        .sold-icon__circle{
          background-color: $color-forsale;
        }
        .sold-icon__tooltip{
          border-color: $color-forsale;
        }
      }
      &__circle{
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        background-color: $color-sold;
      }
      &__tooltip{
        pointer-events: none;
        opacity: 0;
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translate3d(0,-50%,0);
        text-transform: uppercase;
        font-weight: 500;
        font-size: 9px;
        display: block;
        padding: 3px;
        border: 1px solid $color-sold;
        border-radius: 4px;
        transition: .3s;
        white-space: nowrap;
      }
      &__no-images{
        text-align: center;
        font-size: 20px;
        color: $color-font-light;
      }
    }
    & > img{
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__content{
    padding: 20px 0;
    .title{
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 15px;
      .#{$prefix}-product-item:hover &{
        text-decoration: underline;
      }
      @media (max-width: $breakpoint-xs--max) {
        font-size: 14px;
      }
      @media print {
        font-size: 16px!important;
        margin-bottom: 10px!important;
      }
    }
    &__info{
      margin-bottom: 15px;
      .subtitle{
        color: $color-font-light;
        font-family: $font-primary;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.2;
        letter-spacing: 0;
      }
      .paragraph{
        font-size: 12px;
        font-weight: 500;
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        @media (min-width: $breakpoint-s) {
          font-size: 14px;
        }
        @media print {
          font-size: 12px;
        }
        .tooltip{
          font-style: normal;
          margin-left: 7px;
          top: -1px;
          .tooltip__hovered{
            width: 300px;
            font-size: 12px;
            font-style: italic;
          }
          .circle-icon{
            text-transform: lowercase;
          }
        }
        &.estimated-price{
          font-style: italic;
        }
        &.serial-number{
          display: flex;
          align-items: center;
        }
        .star-icon{
          font-size: 15px!important;
          margin-right: 5px;
          color: $color-gold;
        }
      }
      & > p{
        margin-bottom: 5px;
      }

      .carats-group {
        display: flex;
        column-gap: 20px;
        align-items: flex-start;
        .carats {
          padding: 5px 15px;
          border-radius: 30px;
          background-color: #ebedec;
          text-align: center;
          &:nth-child(1){
            background-color: #FAF6F1;
          }
          p {
            display: block;
          }
        }
      }
    }
  }
}