
.zoom-image-carousel{
  width: 100%;
  height: 100%;
  position: relative;
  .imageContainer {
    width: 100%;
    height: 100%;
    .image {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      cursor: grab;
    }
  }

  .zoom-buttons{
    width: 100%;
    position: absolute;
    bottom: 20px;
    display: flex;
    justify-content: center;
    column-gap: 20px;

    .vca-btn{
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg{
        font-size: 22px;
      }
    }
  }
}