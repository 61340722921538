@import "../theme/scss/variables/variables";





.main-header{
  top: 0;
  left: 0;
  width: 100%;
  background-color: $color-primary;
  border-bottom: 1px solid $color-border;
  height: $header-height;
  @include srss-themify($themes) {
    background-color: getThemeColor("main-header");
  }
  @media print{
    display: none;
  }
  &__wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
  &__logo{
    & > a {
      display: block;
      width: 160px;
      background-color:transparent;
      @media (min-width: $breakpoint-xs){
        width: 200px;
      }
      & > img{
        display: block;
        width: 100%;
      }
    }
  }
  &__menu{
    display: flex;
    align-items: center;
    padding-left: 10px;
    &__item{
      display: flex;
      align-items: center;
      &.border-left {
        border-left: 1px solid $color-border;
      }
      & > a,
      & > span{
        display: block;
        font-size: 14px;
        padding: 5px 5px;
        @media (min-width: $breakpoint-xs){
          font-size: 16px;
          padding: 5px 15px;
        }
      }
      .item-link{
        text-decoration: none;
        color: $color-font-primary;
        cursor: pointer;
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
}


.main-content{
  min-height: calc(100vh - $header-height);
  background-color: $white;
  height: 1px;
}