.MuiPopover-root{
  .MuiPickersToolbar-toolbar{
    background-color: $black;
  }
  .MuiPickersDay-daySelected{
    background-color: $black;
    color: $white;
    &:hover{
      background-color: $black;
    }
  }
  .MuiPickersDay-current{
    font-weight: bold;
    text-decoration: underline;
    &:not(.MuiPickersDay-dayDisabled){
      color: $black;
    }
    &.MuiPickersDay-daySelected{
      color: $white;
    }
  }
}