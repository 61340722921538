@import "../../theme/scss/variables/variables";

.loading-container {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    opacity: 0.9;
    z-index: 999999999;
  }
  .loading-text {
    z-index: 9999999;
    position: relative; 
    border-bottom: 4px solid #f2f2f2;
    color: #f2f2f2;
    font-size: 2em;
    letter-spacing: 3px;
    line-height: 1.4;
  }
  .loading-text::before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    color: $black;
    border-bottom: 4px solid $color-primary;
    animation: lod 1s linear infinite;
    overflow: hidden;
    @include srss-themify($themes) {
      border-color: getThemeColor("primary");
    }
  }
  @keyframes lod {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  