@mixin heading-1() {
  margin-bottom: 30px; //To Check later
  font-family: $font-primary;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0;
  font-size: 24px;
  @media (min-width: $breakpoint-s) {
    font-size: 40px;
  }
  @media print {
    font-size: 18px;
  }
}
@mixin heading-2() {
  margin-bottom: 20px; //To Check later
  font-family: $font-primary;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0;
  font-size: 18px;
  @media (min-width: $breakpoint-s) {
    font-size: 22px;
  }
  @media print {
    font-size: 14px;
  }
}
@mixin heading-3() {
  margin-bottom: 20px; //To Check later
  font-family: $font-primary;
  font-style: normal;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0;
  font-size: 16px;
  @media (min-width: $breakpoint-s) {
    font-size: 18px;
  }
}
@mixin heading-4() {
  font-family: $font-primary;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.2;
  letter-spacing: 0;
}

@mixin paragraph() {
  margin-bottom: 10px; //To Check later
  font-family: $font-primary;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0;
  @media (min-width: $breakpoint-s) {
    font-size: 16px;
  }
  @media print {
    font-size: 12px;
  }
}





.heading-1,
.heading-2,
.heading-3,
.heading-4,
.paragraph
{
  &:last-child{
    margin-bottom: 0;
  }
  &.white{
    color: $white;
  }
  &.center{
    text-align: center;
  }
}

.heading-1 {
  @include heading-1();
}
.heading-2 {
  @include heading-2();
}
.heading-3 {
  @include heading-3();
}
.heading-4 {
  @include heading-4();
}

.paragraph {
  @include paragraph();
}


.text-center {
  text-align: center;
}

.fw-bold{
  font-weight: 600!important;
}
.fw-xbold{
  font-weight: 700!important;
}

.fw-normal{
  font-weight: 600;
}

.f-italic{
  font-style: italic;
}