.tooltip{
  flex-shrink: 0;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  &:hover{
    .tooltip__hovered{
      display: block;
    }
  }
  &--right{
    .tooltip__hovered{
      left: 0px;
      right: auto;
    }
  }
  &--top-right{
    .tooltip__hovered{
      top: 0;
      left: 50px;
      right: auto;
    }
  }
  &__hovered{
    display: none;
    z-index: 2;
    width: 250px;
    position: absolute;
    top: 40px;
    right: 0px;
    background-color: $white;
    border: 1px solid $color-border;
    padding: 10px;
    font-family: $font-primary;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0;
    box-shadow: 0 0 20px rgba(0,0,0,.1);
  }
}