@import "../../theme/scss/variables/variables";

.page-duplicatas {
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	&__body{
		flex-grow: 1;
	}

	.duplicatas-loading {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 50px;

		&__loader {
			margin-bottom: 20px;

			>* {
				color: $color-font-primary !important;
			}
		}
	}

	.duplicatas {
		display: flex;
		width: 100%;
		gap: 20px;

		&__btns {
			border-top: 1px solid $color-border;
			margin-top: 40px;
			position: sticky;
			bottom: 0;
			background-color: $white;
			padding: 20px 0;

			.vca-btn {
				@media (min-width: $breakpoint-xs) {
					&.keep-button {
						width: 150px;
					}

					&.button-sold-twice {
						width: 240px;
					}

					&.vca-btn--xsmall {
						width: 100px;
					}
				}

				@media (max-width: $breakpoint-xs--max) {
					font-size: 13px;
				}
			}
		}
	}

	.duplicatas-col {
		width: 50%;
		flex-basis: 50%;
		padding-top: 20px;
	}
}