

.mb-0{
  margin-bottom: 0;
}
.mb-10px{
  margin-bottom: 10px;
}
.mb-20px{
  margin-bottom: 20px;
}
.mb-30px{
  margin-bottom: 30px;
}
.mb-40px{
  margin-bottom: 40px;
}


.mt-10px{
  margin-top: 10px;
}
.mt-20px{
  margin-top: 20px;
}
.mt-30px{
  margin-top: 30px;
}
.mt-40px{
  margin-top: 50px;
}
.mt-50px{
  margin-top: 50px;
}