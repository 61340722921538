// Global
$white: #fff;
$black: #000;


// fonts
$color-font-primary: #000;
$color-font-light: #555;

//colors
$color-primary: #e8f1f1;
$color-border: #DEDEDE;


$grey-2: #ebedec;

$bg-inputs: #F1F1F1;
$bg-inputs-disabled: #dadada;

$color-bg-product: $grey-2;

$color-error: #dd0000;
$color-gold: #c6b078;



