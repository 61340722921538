.price-block {
  display: flex;

  .price {
    padding-left: 10px;
  }

  .price-eur {
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    .tooltip {
      font-style: normal;
      margin-left: 7px;

      .tooltip__hovered {
        width: 300px;
        font-size: 12px;
        font-style: italic;
      }

      .circle-icon {
        text-transform: lowercase;
      }
    }
  }

  .estimated-price {
    font-size: 14px;
    font-style: italic;
  }

  .title {
    @media print {
      margin-bottom: 0;
    }
  }
}