.#{$prefix}-textfield {
  display: block;

  &__label {
    font-size: 16;
    margin-bottom: 10px;
  }

  &__field {
    width: 100%;

    >input {
      border-radius: 0;
      padding: 16px 14px;
      border: 1px solid $color-border;
      background-color: $bg-inputs;
      appearance: none;
      -webkit-appearance: none;

      &[type="search"] {
        padding: 16px 14px 16px 0px;
      }

      &:hover {
        border-color: $black;
      }

      &.error {
        border-color: $color-error;
      }
    }

    .MuiFormControl-root {
      width: 100%;

      .MuiOutlinedInput-adornedStart {
        padding-left: 0px;
      }

      .MuiOutlinedInput-adornedEnd {
        padding-right: 0px;
      }

      .MuiOutlinedInput-adornedEnd {
        input {
          border-right: 0 !important;
        }
      }

      .MuiInputAdornment-root.MuiInputAdornment-positionStart,
      .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
        margin-right: 0;
        margin-left: 0;
        padding-left: 7px;
        padding-right: 7px;
        height: auto;

        &+input {
          border-left: 0;
        }
      }
    }

    .MuiInputBase-root {
      background-color: $bg-inputs;

      &.MuiAutocomplete-inputRoot {
        padding: 0;
      }

      &.Mui-error {
        border-color: $color-error;
      }

      &.Mui-disabled {
        opacity: 0.8;
        background-color: $bg-inputs-disabled;

        &>textarea,
        &>input {
          background-color: $bg-inputs-disabled;
        }
      }

      &.Mui-focused {

        &>textarea,
        &>input,
        &>fieldset {
          border-width: 1px;
        }
      }

      &>textarea,
      &.MuiAutocomplete-inputRoot>input,
      &>input {
        border-radius: 0;
        padding: 16px 14px;
        border: 1px solid $color-border;
        background-color: $bg-inputs;
        appearance: none;
        -webkit-appearance: none;



        &[type="search"] {
          padding: 16px 14px 16px 0px;
          .has-sub-filter & {
            padding: 10px 14px 10px 0;
            font-size: 14px;
          }
        }
        .has-sub-filter & {
          padding: 10px 14px;
          font-size: 14px;
          //font-size: 0.9em;
        }

      }

      &.Mui-disabled>fieldset,
      &>fieldset {
        border-radius: 0;
        border: 1px solid $color-border;
      }

      &.MuiOutlinedInput-multiline {
        padding: 0;
      }
    }

    &.is-datepicker-component {
      .MuiInputBase-root {
        border: 1px solid $color-border;

        &.Mui-error {
          border-color: $color-error;
        }

        &:hover {
          border-color: $black;

          &.Mui-error {
            border-color: $color-error;
          }
        }

        &:after,
        &:before {
          display: none;
        }

        input {
          border: 0;
        }

        .MuiInputAdornment-positionEnd {
          padding-left: 5px;
          padding-right: 5px;
          border-radius: 50px;

          &:hover {
            background-color: $color-border;
          }

          button {
            background-color: transparent;
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }

    &--autocomplete {
      .MuiInputBase-root.Mui-disabled {
        background-color: $bg-inputs;
      }

      .MuiAutocomplete-endAdornment{
        bottom: 13px;
        top: auto;
      }

      >.MuiAutocomplete-root {
        >.MuiFormControl-root {
          >.MuiInputBase-root {
            .MuiAutocomplete-tag {
              margin: 5px 3px;
            }

            >input.MuiInputBase-input {
              flex-grow: 1;
              flex-shrink: 0;
              width: 100%;
            }
          }
        }
      }
    }
  }

  .textfield-small {
    .MuiInputBase-root {

      &>textarea,
      &.MuiAutocomplete-inputRoot>input,
      &>input {
        padding: 10px 8px;
      }
    }
  }

  input[type="number"] {

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    -moz-appearance: none;
  }

  &.input-with-adornment-xsmall-plr{
    .vca-textfield__field .MuiInputBase-root > input{
      padding-left:10px;
      padding-right:2px;
    }
  }
}

.MuiIconButton-colorSecondary,
.MuiCheckbox-colorSecondary.Mui-checked {
  color: #000 !important;
}

.MuiCheckbox-root {
  &.Mui-disabled {
    .MuiIconButton-label {
      opacity: .38;
    }
  }
}