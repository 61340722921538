@import "../../../theme/scss/variables/variables";

.duplicata {

	@media (max-width: $breakpoint-xs--max) {
		* {
			font-size: 12px !important;
		}
	}
	&__loading{
		display: flex;
		align-items: center;
		justify-content: center;
		height: 200px;
		> * {
			color: $color-font-primary!important;
		}
	}

	&__img {
		display: block;
		max-width: 350px;
		margin-left: auto;
		margin-right: auto;
		width: 100%;
	}

	&__carousel {
		display: flex;
		gap: 10px;

		&__thumbnails {
			width: 50px;
			flex-basis: 50px;
			display: flex;
			flex-direction: column;
			row-gap: 10px;

		}
		&__thumbnail {
			cursor: pointer;
			width: 50px;
			height: 50px;
			border: 1px solid $color-border;

			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}

		&__image {
			flex-grow: 1;
		}
	}

	.price-block {
		flex-direction: column;

		.title {
			margin-bottom: 5px;
		}

		.price {
			padding-left: 0;
		}
	}

	&__info {
		margin: 0 auto;
		max-width: 800px;

		&__line {
			display: flex;
			align-items: center;
			margin-bottom: 20px;

			@media print {
				margin-bottom: 13px;
			}

			&.long-content {
				align-items: flex-start;
				flex-wrap: wrap;

				.duplicata__info__line__label {
					width: 100%;
					flex-basis: 100%;
				}
			}

			&.is-url {
				@media print {
					display: none;
				}
			}

			&__label {
				flex-shrink: 0;
				width: 120px;
				flex-basis: 120px;
				margin-bottom: 10px;
				font-weight: 600;

				.paragraph {
					font-weight: 600;
				}

				@media (min-width: $breakpoint-s) {
					width: 200px;
					flex-basis: 200px;
				}

				@media print {
					margin-bottom: 3px;
				}

				a {
					color: #2676ae;
				}
			}

			&__content {
				flex-grow: 1;
				margin-bottom: 10px;

				a {
					/* These are technically the same, but use both */
					overflow-wrap: break-word;
					word-wrap: break-word;

					-ms-word-break: break-all;
					/* This is the dangerous one in WebKit, as it breaks things wherever */
					word-break: break-all;
					/* Instead use this non-standard one: */
					word-break: break-word;

					/* Adds a hyphen where the word breaks, if supported (No Blink) */
					-ms-hyphens: auto;
					-moz-hyphens: auto;
					-webkit-hyphens: auto;
					hyphens: auto;
				}
			}
		}
	}

	.stone-admin-row {
		grid-template-columns: 1fr;
	}
}