@import "../../../theme/scss/variables/variables";

.stone-admin-row {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
  width: 100%;
  @media (min-width: $breakpoint-xs) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }

  .stone-admin-col {
    padding: 20px;
    border: 1px solid $color-border;
    border-radius: 4px;

    &__group {
      display: grid;
      grid-template-columns: 85px 1fr;
      margin-bottom: 7px;
      & > div:first-child{
        font-weight: bold;
      }
    }
  }
}