
b,
strong {
  font-weight: 600 !important;
}



h1, h2, h3, h4, h5, h6, p, span {
  margin: 0;
  padding: 0;
}